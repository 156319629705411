<template>
  <div class="row py-3 py-lg-0 flex-wrap gx-4 gy-6">
    <!-- menu row-->
    <div class="col" v-for="(mainCategory) in categoryData" :key="mainCategory">
<!--      <h6 class="dropdown-heading">{{ mainCategory.name }}</h6>-->
      <ul class="list-unstyled">
        <li class="dropdown-list-item" v-for="(item) in mainCategory.subCategories" :key="item">
          <router-link :to="`/category?categoryId=${item.id}`" class="dropdown-item">
            {{ item.name }}
          </router-link>
        </li>
<!--        <li class="dropdown-list-item">-->
<!--&lt;!&ndash;          <router-link class="dropdown-item fst-italic small-text" :to="`/category?categoryId=${mainCategory.id}`">>> 所有{{ mainCategory.name }}</router-link>&ndash;&gt;-->
<!--          <router-link class="dropdown-item dropdown-link-all" :to="`/category?categoryId=${mainCategory.id}`">View All</router-link>-->
<!--        </li>-->
      </ul>
    </div>
    <!-- / menu row-->
  </div>

  <!--  <div class="align-items-center justify-content-between mt-5 d-none d-lg-flex">-->
  <!--    {{ #each(limit slideshow - brands.entries 6) }}-->
  <!--    <div class="me-5 f-w-20">-->
  <!--      <a class="d-block" href="{{webRoot}}/category.html">-->
  <!--        <picture>-->
  <!--          <img class="img-fluid d-table mx-auto" src="{{ img }}" alt="{{ config.defaultImgAlt }}">-->
  <!--        </picture>-->
  <!--      </a>-->
  <!--    </div>-->
  <!--    {{ /each }}-->
  <!--  </div>-->
</template>
<script>
import {useStore} from "vuex";

export default {
  name: "DropdownLinksOne",
  setup() {
    const category = useStore().state.category
    console.log(category)
    return {categoryData:category};
  }
};
</script>
<style scoped>
/**
.dropdown-heading {
  font-size: 100% !important;
  color: #323434 !important;
}

.dropdown-item {
  //font-size: small;
  font-size: 100% !important;
  color: #6b7280 !important;
}

.small-text {
  font-size: 90% !important;
}

 */
</style>
   