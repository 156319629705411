<template>
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><router-link to="home">Home</router-link></li>
<!--      <li class="breadcrumb-item">-->
<!--        <router-link-->
<!--          :to="`category?categoryId=${isParent ? id:parentId}`">{{ isParent ? name : parentName }}</router-link></li>-->
<!--      <li class="breadcrumb-item active" v-if="!isParent" aria-current="page">{{ name }}</li>-->
      <li class="breadcrumb-item active" aria-current="page">{{ name }}</li>
    </ol>
  </nav>
</template>
<script>
import {inject} from "vue";

export default {
  name: "Breadcrumbs",
  setup() {
    console.log(inject('categoryData'))
    // 获取上级组件传递的导航栏数据
    return inject('categoryData')
  }
}
</script>