<script>
import AppToolbar from "@/components/toolbar/toolbar.vue";
import {useStore} from "vuex";
import {useRoute} from "vue-router";
import {provide} from "vue";
import ListingCard from "@/components/listing-cards/listing-card.vue";

export default {
  name: 'Category',
  components: {
    ListingCard,
    AppToolbar
  },
  methods: {
    setCategoryData() {
      const category = this.$store.state.category
      const route = useRoute();
      const catId = route.query.categoryId;
      let categoryData = {}
      category.forEach(item => {
        if (String(item.id) === (catId)) {
          categoryData = {
            id: item.id,
            isParent: true,
            name: item.name,
            categoryDesc:item.categoryDesc
          }
        }
        // 判断item下是否有subCategories，如果有，则对其进行遍历并判断是否有id与catId相等的，如果有，则返回item
        if (item.subCategories) {
          item.subCategories.forEach(subItem => {
            if (String(subItem.id) === catId) {
              categoryData = {
                id: subItem.id,
                isParent: false,
                name: subItem.name,
                parentId: item.id,
                parentName: item.name,
                categoryDesc:subItem.categoryDesc
              }
            }
          })
        }
      })
      console.log(categoryData,"cateData")
      return categoryData
    },
    setProdData() {
      const product = this.$store.state.product
      let productData = []
      let catId = this.currentCategory.id;
      product.forEach(item => {
        console.log(this.currentCategory.isParent)
        if (this.currentCategory.isParent) {
          if (String(item.mainCategoryId) === String(catId)) {
            productData.push(item)
          }
        } else {
          if (String(item.subCategoryId) === String(catId)) {
            productData.push(item)
          }
        }
      })
      return productData
    }
  },
  created() {
    this.currentCategory = this.setCategoryData()
    this.currentProdData = this.setProdData()
    provide('categoryData', this.currentCategory)
  },
  data() {
    return {
      currentCategory: {},
      currentProdData: []
    }
  },
}
</script>

<template>
  <!-- Main Section-->
  <section class="mt-0">
    <!-- Page Content Goes Here -->

    <!-- Category Top Banner -->
    <div class="py-10 bg-img-cover bg-overlay-dark position-relative overflow-hidden bg-pos-center-center rounded-0"
         :style="{backgroundImage: `url('/assets/images/banners/banner-category-top.jpg'`}">
      <div class="container-fluid position-relative z-index-20" data-aosb="fade-right" data-aos-delay="300">
        <h1 class="fw-bold display-6 mb-4 text-white">{{ currentCategory.name }}</h1>
        <div class="col-12 col-md-6">
          <p class="text-white mb-0 fs-5">
            {{ currentCategory.categoryDesc }}
          </p>
        </div>
      </div>
    </div>
    <!-- Category Top Banner -->

    <div class="container-fluid" data-aosb="fade-in">
      <!-- Category Toolbar-->
      <AppToolbar :prdCount="currentProdData.length"/>
      <!-- /Category Toolbar-->

      <!-- Products-->
      <div class="row g-4">
        <div class="col-12 col-sm-6 col-lg-4" v-for="prod in currentProdData">
          <ListingCard :detailsId="`${prod.detailsId}`" :imgUrl="`${prod.img}`" :options="`${prod.options}`"
                       :title="`${prod.name}`" :tagText="prod.tagText" />
        </div>
      </div>
      <!-- / Products-->

      <!-- Pagination-->
      <!--      {{> pagination/pagination type="products" amount="9 of 121" }}-->
      <!-- / Pagination-->
    </div>

    <!-- /Page Content -->
  </section>
</template>

<style scoped>

</style>