<template>
  <nav class=" m-0" style="position: relative;z-index: 888" aria-label="breadcrumb">
    <ol class="breadcrumb  m-0">
      <li class="breadcrumb-item breadcrumb-light"><router-link to="home">Home</router-link></li>
<!--      <li class="breadcrumb-item breadcrumb-light"><router-link-->
<!--          :to="`category?categoryId=${curCateGory.isParent ? curCateGory.id:curCateGory.parentId}`">{{-->
<!--          curCateGory.isParent ? curCateGory.name : curCateGory.parentName-->
<!--        }}</router-link></li>-->
<!--      <li class="breadcrumb-item breadcrumb-light" v-if="!curCateGory.isParent"> <router-link-->
<!--          :to="`category?categoryId=${curCateGory.id}`"-->
<!--          >{{ curCateGory.name }}</router-link>-->
<!--      </li>-->
      <li class="breadcrumb-item breadcrumb-light"> <router-link
          :to="`category?categoryId=${curCateGory.id}`"
          >{{ curCateGory.name }}</router-link>
      </li>
      <li class="breadcrumb-item breadcrumb-light active" aria-current="page">{{ detailsName }}</li>
    </ol>
  </nav>
</template>
<script>

import {useRoute} from "vue-router";

export default {
  name: "BreadcrumbsLight",
  props: {
    catId: String,
    detailsName: String
  },
  created() {
    console.log("inner")
    this.curCateGory = this.setCategoryData()
    console.log(this.curCateGory,"cateData")
  },
  data() {
    return {
      curCateGory: {}
    }
  },
  methods: {
    setCategoryData() {
      const category = this.$store.state.category
      const catId = this.catId;
      let categoryData = {}
      category.forEach(item => {
        if (String(item.id) === String(catId)) {
          categoryData = {
            id: item.id,
            isParent: true,
            name: item.name
          }
        }
        // 判断item下是否有subCategories，如果有，则对其进行遍历并判断是否有id与catId相等的，如果有，则返回item
        if (item.subCategories) {
          item.subCategories.forEach(subItem => {
            if (String(subItem.id) === String(catId)) {
              categoryData = {
                id: subItem.id,
                isParent: false,
                name: subItem.name,
                parentId: item.id,
                parentName: item.name
              }
            }
          })
        }
      })
      return categoryData
    },
  }
}
</script>