import {createApp} from 'vue'
import App from './App.vue'

// import '/assets/css/libs.bundle.css'
// import '@/assets/css/theme.bundle.css'
// import '@/assets/css/fixAOS.css'
// import '@/assets/css/bootstrap.css'
// import '@/assets/js/bootstrap.bundle.min'
// import '@/assets/js/misc.js'
// import '@/assets/js/vendor.bundle'
// import '@/assets/js/components/medium-zoom'
// import '@/assets/js/theme.bundle'

// roter store
import router from "@/router";
import store from "@/store/store";


const app = createApp(App)
app.use(router)
app.use(store)
app.mount('#app')
