export const categoryData = [
    {
        "id": 1,
        "mainCategory": "upper outer garment",
        "name": "upper outer garment",
        "subCategories": [
            {"id": 101, "name": "HOODIES", "categoryDesc": "HOODIES"},
            {"id": 102, "name": "SWEATSHIRTS", "categoryDesc": "SWEATSHIRTS"},
            {"id": 103, "name": "HAT", "categoryDesc": "HAT"},
            {"id": 104, "name": "T-SHIRT", "categoryDesc": "T-SHIRT"},
        ]
    },
    {
        "id": 2,
        "mainCategory": "裤子",
        "name": "裤子",
        "subCategories": [
            {"id": 201, "name": "SWIMWEAR", "categoryDesc": "SWIMWEAR"},
            {"id": 202, "name": "YOGAWEAR", "categoryDesc": "YOGAWEAR"},
            {"id": 203, "name": "SPORTSWEAR", "categoryDesc": "SPORTSWEAR"},
            {"id": 204, "name": "SWEATPANTS", "categoryDesc": "SWEATPANTS"},
        ]
    },
    {
        "id": 3,
        "mainCategory": "鞋类",
        "name": "鞋类",
        "subCategories": [
            {"id": 301, "name": "JACKET", "categoryDesc": "JACKET"},
        ]
    },
];


