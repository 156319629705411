export const detail = [{
    "id": 5001,
    "parentCategoryId": 104,
    "title": "纯棉短袖T恤",
    "description": "纯棉材质， comfortable and soft",
    "imgs": ["tshirts/1648706621895415.jpg"]
}, {
    "id": 5002,
    "parentCategoryId": 104,
    "title": "纯棉短袖T恤",
    "description": "纯棉材质， comfortable and soft",
    "imgs": ["tshirts/1648706640299618.jpg"]
}, {
    "id": 5003,
    "parentCategoryId": 104,
    "title": "纯棉短袖T恤",
    "description": "纯棉材质， comfortable and soft",
    "imgs": ["tshirts/1648706654845256.jpg"]
}, {
    "id": 5004,
    "parentCategoryId": 104,
    "title": "纯棉短袖T恤",
    "description": "纯棉材质， comfortable and soft",
    "imgs": ["tshirts/1648706671876578.jpg"]
}, {
    "id": 5005,
    "parentCategoryId": 104,
    "title": "纯棉短袖T恤",
    "description": "纯棉材质， comfortable and soft",
    "imgs": ["tshirts/1648706686481346.jpg"]
}, {
    "id": 5006,
    "parentCategoryId": 104,
    "title": "纯棉短袖T恤",
    "description": "纯棉材质， comfortable and soft",
    "imgs": ["tshirts/1648706699207806.jpg"]
}, {
    "id": 5007,
    "parentCategoryId": 104,
    "title": "纯棉短袖T恤",
    "description": "纯棉材质， comfortable and soft",
    "imgs": ["tshirts/1648706716570495.jpg"]
}, {
    "id": 5008,
    "parentCategoryId": 104,
    "title": "纯棉短袖T恤",
    "description": "纯棉材质， comfortable and soft",
    "imgs": ["tshirts/1648706730475926.jpg"]
}, {
    "id": 5009,
    "parentCategoryId": 104,
    "title": "纯棉短袖T恤",
    "description": "纯棉材质， comfortable and soft",
    "imgs": ["tshirts/1648706744202460.jpg"]
}, {
    "id": 5010,
    "parentCategoryId": 104,
    "title": "纯棉短袖T恤",
    "description": "纯棉材质， comfortable and soft",
    "imgs": ["tshirts/O1CN012Jf8cz1whJTihqNRg_!!2213972956339-0-cib.jpg"]
}, {
    "id": 5011,
    "parentCategoryId": 101,
    "title": "Hoodie-1",
    "description": "好就一个字",
    "imgs": ["hoodies/1648704490843220.jpg"]
},
    {
        "id": 5012,
        "parentCategoryId": 101,
        "title": "Hoodie-2",
        "description": "好就一个字",
        "imgs": ["hoodies/1648704518355223.jpg"]
    },
    {
        "id": 5013,
        "parentCategoryId": 101,
        "title": "Hoodie-3",
        "description": "好就一个字",
        "imgs": ["hoodies/1648704528718307.jpg"]
    },
    {
        "id": 5014,
        "parentCategoryId": 101,
        "title": "Hoodie-4",
        "description": "好就一个字",
        "imgs": ["hoodies/1648704556224184.jpg"]
    },
    {
        "id": 5015,
        "parentCategoryId": 101,
        "title": "Hoodie-5",
        "description": "好就一个字",
        "imgs": ["hoodies/1648704567766477.jpg"]
    },
    {
        "id": 5016,
        "parentCategoryId": 101,
        "title": "Hoodie-6",
        "description": "好就一个字",
        "imgs": ["hoodies/1648704578180583.jpg"]
    },
    {
        "id": 5017,
        "parentCategoryId": 101,
        "title": "Hoodie-7",
        "description": "好就一个字",
        "imgs": ["hoodies/1648704628743742.jpg"]
    },
    {
        "id": 5018,
        "parentCategoryId": 101,
        "title": "Hoodie-8",
        "description": "好就一个字",
        "imgs": ["hoodies/1648704669261103.jpg"]
    },
    {
        "id": 5019,
        "parentCategoryId": 101,
        "title": "Hoodie-9",
        "description": "好就一个字",
        "imgs": ["hoodies/1648704683465433.jpg"]
    }, {
        "id": 5020,
        "parentCategoryId": 102,
        "title": "SWEATSHIRTS-1",
        "description": "",
        "imgs": ["sweatshirts/1648704490843220.jpg"]
    },
    {
        "id": 5021,
        "parentCategoryId": 102,
        "title": "SWEATSHIRTS-2",
        "description": "",
        "imgs": ["sweatshirts/1648704518355223.jpg"]
    },
    {
        "id": 5022,
        "parentCategoryId": 102,
        "title": "SWEATSHIRTS-3",
        "description": "",
        "imgs": ["sweatshirts/1648704528718307.jpg"]
    },
    {
        "id": 5023,
        "parentCategoryId": 102,
        "title": "SWEATSHIRTS-4",
        "description": "",
        "imgs": ["sweatshirts/1648704556224184.jpg"]
    },
    {
        "id": 5024,
        "parentCategoryId": 102,
        "title": "SWEATSHIRTS-5",
        "description": "",
        "imgs": ["sweatshirts/1648704567766477.jpg"]
    },
    {
        "id": 5025,
        "parentCategoryId": 102,
        "title": "SWEATSHIRTS-6",
        "description": "",
        "imgs": ["sweatshirts/1648704578180583.jpg"]
    },
    {
        "id": 5026,
        "parentCategoryId": 102,
        "title": "SWEATSHIRTS-7",
        "description": "",
        "imgs": ["sweatshirts/1648704628743742.jpg"]
    },
    {
        "id": 5027,
        "parentCategoryId": 102,
        "title": "SWEATSHIRTS-8",
        "description": "",
        "imgs": ["sweatshirts/1648704669261103.jpg"]
    },
    {
        "id": 5028,
        "parentCategoryId": 102,
        "title": "SWEATSHIRTS-9",
        "description": "",
        "imgs": ["sweatshirts/1648704683465433.jpg"]
    },
    {
        "id": 5029,
        "parentCategoryId": 103,
        "title": "HAT-1",
        "description": "",
        "imgs": ["hats/1649821134179668.jpg"]
    },
    {
        "id": 5030,
        "parentCategoryId": 103,
        "title": "HAT-2",
        "description": "",
        "imgs": ["hats/1649821157461416.jpg"]
    },
    {
        "id": 5031,
        "parentCategoryId": 103,
        "title": "HAT-3",
        "description": "",
        "imgs": ["hats/1649821171637587.jpg"]
    },
    {
        "id": 5032,
        "parentCategoryId": 103,
        "title": "HAT-4",
        "description": "",
        "imgs": ["hats/1649821185542028.jpg"]
    },
    {
        "id": 5033,
        "parentCategoryId": 103,
        "title": "HAT-5",
        "description": "",
        "imgs": ["hats/1649821202728230.jpg"]
    },
    {
        "id": 5034,
        "parentCategoryId": 103,
        "title": "HAT-6",
        "description": "",
        "imgs": ["hats/1649821219239345.jpg"]
    },
    {
        "id": 5035,
        "parentCategoryId": 103,
        "title": "HAT-7",
        "description": "",
        "imgs": ["hats/1649821234683320.jpg"]
    },
    {
        "id": 5036,
        "parentCategoryId": 103,
        "title": "HAT-8",
        "description": "",
        "imgs": ["hats/1649821247222857.jpg"]
    },
    {
        "id": 5037,
        "parentCategoryId": 201,
        "title": "SWIMWEAR-1",
        "description": "",
        "imgs": ["swimwear/1649820117870136.png"]
    },
    {
        "id": 5038,
        "parentCategoryId": 201,
        "title": "SWIMWEAR-2",
        "description": "",
        "imgs": ["swimwear/1649820136472056.png"]
    },
    {
        "id": 5039,
        "parentCategoryId": 201,
        "title": "SWIMWEAR-3",
        "description": "",
        "imgs": ["swimwear/1649820154206966.png"]
    },
    {
        "id": 5040,
        "parentCategoryId": 201,
        "title": "SWIMWEAR-4",
        "description": "",
        "imgs": ["swimwear/1649820187308323.jpg"]
    },
    {
        "id": 5041,
        "parentCategoryId": 201,
        "title": "SWIMWEAR-5",
        "description": "",
        "imgs": ["swimwear/1649820206795058.jpg"]
    },
    {
        "id": 5042,
        "parentCategoryId": 201,
        "title": "SWIMWEAR-6",
        "description": "",
        "imgs": ["swimwear/1649820224252208.jpg"]
    },
    {
        "id": 5043,
        "parentCategoryId": 201,
        "title": "SWIMWEAR-7",
        "description": "",
        "imgs": ["swimwear/1649820311605348.jpg"]
    },
    {
        "id": 5044,
        "parentCategoryId": 201,
        "title": "SWIMWEAR-8",
        "description": "",
        "imgs": ["swimwear/1649820342987561.png"]
    },
    {
        "id": 5045,
        "parentCategoryId": 201,
        "title": "SWIMWEAR-9",
        "description": "",
        "imgs": ["swimwear/1649820362196019.png"]
    },{
        "id": 5046,
        "parentCategoryId": 202,
        "title": "YOGAWEAR-1",
        "description": "",
        "imgs": ["yogaWear/1649820433394246.png"]
    },
    {
        "id": 5047,
        "parentCategoryId": 202,
        "title": "YOGAWEAR-2",
        "description": "",
        "imgs": ["yogaWear/1649820468999289.jpg"]
    },
    {
        "id": 5048,
        "parentCategoryId": 202,
        "title": "YOGAWEAR-3",
        "description": "",
        "imgs": ["yogaWear/1649820486730560.jpg"]
    },
    {
        "id": 5049,
        "parentCategoryId": 202,
        "title": "YOGAWEAR-4",
        "description": "",
        "imgs": ["yogaWear/1649820518317925.jpg"]
    },
    {
        "id": 5050,
        "parentCategoryId": 202,
        "title": "YOGAWEAR-5",
        "description": "",
        "imgs": ["yogaWear/1649820556151477.jpg"]
    },
    {
        "id": 5051,
        "parentCategoryId": 202,
        "title": "YOGAWEAR-6",
        "description": "",
        "imgs": ["yogaWear/1649820579138864.jpg"]
    },
    {
        "id": 5052,
        "parentCategoryId": 202,
        "title": "YOGAWEAR-7",
        "description": "",
        "imgs": ["yogaWear/1649820622930370.jpg"]
    },
    {
        "id": 5053,
        "parentCategoryId": 202,
        "title": "YOGAWEAR-8",
        "description": "",
        "imgs": ["yogaWear/1649820657428080.jpg"]
    },
    {
        "id": 5054,
        "parentCategoryId": 202,
        "title": "YOGAWEAR-9",
        "description": "",
        "imgs": ["yogaWear/1649820674908527.jpg"]
    },
    {
        "id": 5055,
        "parentCategoryId": 202,
        "title": "YOGAWEAR-10",
        "description": "",
        "imgs": ["yogaWear/1649820691569746.png"]
    },
    {
        "id": 5056,
        "parentCategoryId": 203,
        "title": "SPORTSWEAR-1",
        "description": "",
        "imgs": ["sportswear/1648706028782845.jpg"]
    },
    {
        "id": 5057,
        "parentCategoryId": 203,
        "title": "SPORTSWEAR-2",
        "description": "",
        "imgs": ["sportswear/1648706071581319.jpg"]
    },
    {
        "id": 5058,
        "parentCategoryId": 203,
        "title": "SPORTSWEAR-3",
        "description": "",
        "imgs": ["sportswear/1648706096662695.jpg"]
    },
    {
        "id": 5059,
        "parentCategoryId": 203,
        "title": "SPORTSWEAR-4",
        "description": "",
        "imgs": ["sportswear/1648706124515686.jpg"]
    },
    {
        "id": 5060,
        "parentCategoryId": 203,
        "title": "SPORTSWEAR-5",
        "description": "",
        "imgs": ["sportswear/1648706138259533.jpg"]
    },
    {
        "id": 5061,
        "parentCategoryId": 203,
        "title": "SPORTSWEAR-6",
        "description": "",
        "imgs": ["sportswear/1648706155274753.jpg"]
    },
    {
        "id": 5062,
        "parentCategoryId": 203,
        "title": "SPORTSWEAR-7",
        "description": "",
        "imgs": ["sportswear/1648706173126468.jpg"]
    },
    {
        "id": 5063,
        "parentCategoryId": 203,
        "title": "SPORTSWEAR-8",
        "description": "",
        "imgs": ["sportswear/1648706192915930.jpg"]
    },
    {
        "id": 5064,
        "parentCategoryId": 203,
        "title": "SPORTSWEAR-9",
        "description": "",
        "imgs": ["sportswear/1648706322333524.jpg"]
    }

]