<script>
export default {
  name: 'ProductGallery',
  props: {
    imgs: Array
  }
}
</script>
<template>
  <div class="row g-3" data-aosb="fade-right">
    <div class="col-12" v-for="img in imgs">
      <picture>
        <img data-zoomable class="img-fluid" :src="`/assets/images/products/${img}`"
             alt="">
      </picture>
    </div>
  </div>
</template>