<template>
  <Container>
    <router-view :key="$route.fullPath"/>
  </Container>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'
import Container from "./layout/container.vue";

// 引入 Bootstrap CSS
// import 'bootstrap/dist/css/bootstrap.min.css'

export default {
  name: 'App',
  components: {
    Container,
    HelloWorld
  }
}
</script>

<style>
@media only screen and (min-width:750px) {
  .container-fluid {
    --bs-gutter-x: 5rem;
  }
}

</style>
