<template>
  <div class="px-3 mt-md-6" data-aosb="fade-in">

    <h1 class="fs-2 fw-bold">{{ title }}</h1>
  </div>
  <!--    <button class="btn btn-dark w-100 mt-4 mb-0 hover-lift-sm hover-boxshadow">Add To Shopping Bag</button>-->

  <!-- Product Accordion -->
  <div class="accordion px-3" id="accordionProduct">
    <div class="accordion-item">
      <h2 class="accordion-header" id="headingOne">
        <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne"
                aria-expanded="true" aria-controls="collapseOne">
          PRODUCT DETAILS
        </button>
      </h2>
      <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne"
           data-bs-parent="#accordionProduct">
        <div class="accordion-body">
          <p class="m-0">{{ description }}</p>
        </div>
      </div>
    </div>
  </div>
  <!-- / Product Accordion-->
</template>
<script>
import {inject} from "vue";

export default {
  name: "ProductInfo",
  props: {
    title: String,
    description: String
  },
  setup() {


  }
}
</script>