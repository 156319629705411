<template>
  <section class="vh-75 vh-lg-60 container-fluid rounded overflow-hidden" data-aosb="fade-in">
    <SwiperHomepageHero/>
  </section>
  <div class="container-fluid">
    <div class="position-relative row my-lg-7 pt-5 pt-lg-0 g-8">
      <div class="bg-text bottom-0 start-0 end-0" data-aosb="fade-up">
        <h2 class="bg-text-title opacity-10"><span class="text-outline-dark">Old</span>Skool</h2>
      </div>
      <div class="col-12 col-md-6 position-relative z-index-20 mb-7 mb-lg-0" data-aosb="fade-right">
          <p class="text-muted title-small">Welcome</p>
        <h3 class="display-3 fw-bold mb-5"><span class="text-outline-dark">WHO</span>  WE WORK WITH</h3>
        <p class="lead">START UP BRANDS. Being new to the industry can be very challenging. Finding clothing manufacturers. designing clothing, selecting materials. configuringsize charts and so on. We are here to guide you through the whole process and to help you establish yourself whilst enjoying your new venture. </p>
        <a href="{{webRoot}}/category.html" class="btn btn-psuedo" role="button">view our products</a>
      </div>
      <div class="col-12 col-md-6 position-relative z-index-20 pe-0" data-aosb="fade-left">
        <picture class="w-50 d-block position-relative z-index-10 border border-white border-4 shadow-lg">
          <img class="img-fluid" src='/assets/images/banners/banner-5.jpg' :alt="config.defaultImgAlt">
        </picture>
        <picture
            class="w-60 d-block me-8 mt-n10 shadow-lg border border-white border-4 position-relative z-index-20 ms-auto">
          <img class="img-fluid" src='/assets/images/banners/banner-6.jpg' :alt="config.defaultImgAlt">
        </picture>
        <picture
            class="w-50 d-block me-8 mt-n7 shadow-lg border border-white border-4 position-absolute top-0 end-0 z-index-0 ">
          <img class="img-fluid" src='/assets/images/banners/banner-7.jpg' :alt="config.defaultImgAlt">
        </picture>
      </div>
    </div>
  </div>
</template>
<script>
import SwiperHomepageHero from "@/components/swiper/swiper-homepage-hero.vue";

export default {
  name: 'Home',
  components: {
    SwiperHomepageHero
  },
  data() {
    return {
      config: {
        defaultImgAlt: 'Default Image Alt'
      }
    };
  }
}
</script>
