export const products = [
    {
        "id": 1001,
        "mainCategoryId": 1,
        "subCategoryId": 104,
        "name": "纯棉短袖T恤",
        "options": "好就一个字",
        "img": "tshirts/1648706621895415.jpg",
        "tagText": "New",
        "detailsId": 5001
    }, {
        "id": 1002,
        "mainCategoryId": 1,
        "subCategoryId": 104,
        "name": "纯棉短袖T恤",
        "options": "好就一个字",
        "img": "tshirts/1648706640299618.jpg",
        "tagText": "New",
        "detailsId": 5002
    }, {
        "id": 1003,
        "mainCategoryId": 1,
        "subCategoryId": 104,
        "name": "纯棉短袖T恤",
        "options": "好就一个字",
        "img": "tshirts/1648706654845256.jpg",
        "tagText": "New",
        "detailsId": 5003
    }, {
        "id": 1004,
        "mainCategoryId": 1,
        "subCategoryId": 104,
        "name": "纯棉短袖T恤",
        "options": "好就一个字",
        "img": "tshirts/1648706671876578.jpg",
        "tagText": "New",
        "detailsId": 5004
    }, {
        "id": 1005,
        "mainCategoryId": 1,
        "subCategoryId": 104,
        "name": "纯棉短袖T恤",
        "options": "好就一个字",
        "img": "tshirts/1648706686481346.jpg",
        "tagText": "New",
        "detailsId": 5005
    }, {
        "id": 1006,
        "mainCategoryId": 1,
        "subCategoryId": 104,
        "name": "纯棉短袖T恤",
        "options": "好就一个字",
        "img": "tshirts/1648706699207806.jpg",
        "tagText": "New",
        "detailsId": 5006
    }, {
        "id": 1007,
        "mainCategoryId": 1,
        "subCategoryId": 104,
        "name": "纯棉短袖T恤",
        "options": "好就一个字",
        "img": "tshirts/1648706716570495.jpg",
        "tagText": "New",
        "detailsId": 5007
    }, {
        "id": 1008,
        "mainCategoryId": 1,
        "subCategoryId": 104,
        "name": "纯棉短袖T恤",
        "options": "好就一个字",
        "img": "tshirts/1648706730475926.jpg",
        "tagText": "New",
        "detailsId": 5008
    }, {
        "id": 1009,
        "mainCategoryId": 1,
        "subCategoryId": 104,
        "name": "纯棉短袖T恤",
        "options": "好就一个字",
        "img": "tshirts/1648706744202460.jpg",
        "tagText": "New",
        "detailsId": 5009
    }, {
        "id": 1040,
        "mainCategoryId": 1,
        "subCategoryId": 104,
        "name": "纯棉短袖T恤",
        "options": "好就一个字",
        "img": "tshirts/O1CN012Jf8cz1whJTihqNRg_!!2213972956339-0-cib.jpg",
        "tagText": "New",
        "detailsId": 5010
    },
    {
        "id": 1011,
        "mainCategoryId": 1,
        "subCategoryId": 101,
        "name": "Hoodie-1",
        "options": "好就一个字",
        "img": "hoodies/1648704490843220.jpg",
        "tagText": "New",
        "detailsId": 5011
    },
    {
        "id": 1012,
        "mainCategoryId": 1,
        "subCategoryId": 101,
        "name": "Hoodie-2",
        "options": "好就一个字",
        "img": "hoodies/1648704518355223.jpg",
        "tagText": "New",
        "detailsId": 5012
    },
    {
        "id": 1013,
        "mainCategoryId": 1,
        "subCategoryId": 101,
        "name": "Hoodie-3",
        "options": "好就一个字",
        "img": "hoodies/1648704528718307.jpg",
        "tagText": "New",
        "detailsId": 5013
    },
    {
        "id": 1014,
        "mainCategoryId": 1,
        "subCategoryId": 101,
        "name": "Hoodie-4",
        "options": "好就一个字",
        "img": "hoodies/1648704556224184.jpg",
        "tagText": "New",
        "detailsId": 5014
    },
    {
        "id": 1015,
        "mainCategoryId": 1,
        "subCategoryId": 101,
        "name": "Hoodie-5",
        "options": "好就一个字",
        "img": "hoodies/1648704567766477.jpg",
        "tagText": "New",
        "detailsId": 5015
    },
    {
        "id": 1016,
        "mainCategoryId": 1,
        "subCategoryId": 101,
        "name": "Hoodie-6",
        "options": "好就一个字",
        "img": "hoodies/1648704578180583.jpg",
        "tagText": "New",
        "detailsId": 5016
    },
    {
        "id": 1017,
        "mainCategoryId": 1,
        "subCategoryId": 101,
        "name": "Hoodie-7",
        "options": "好就一个字",
        "img": "hoodies/1648704628743742.jpg",
        "tagText": "New",
        "detailsId": 5017
    },
    {
        "id": 1018,
        "mainCategoryId": 1,
        "subCategoryId": 101,
        "name": "Hoodie-8",
        "options": "好就一个字",
        "img": "hoodies/1648704669261103.jpg",
        "tagText": "New",
        "detailsId": 5018
    },
    {
        "id": 1019,
        "mainCategoryId": 1,
        "subCategoryId": 101,
        "name": "Hoodie-9",
        "options": "好就一个字",
        "img": "hoodies/1648704683465433.jpg",
        "tagText": "New",
        "detailsId": 5019
    },{
        "id": 1021,
        "mainCategoryId": 1,
        "subCategoryId": 102,
        "name": "SWEATSHIRTS-1",
        "options": "",
        "img": "sweatshirts/1648704490843220.jpg",
        "tagText": "New",
        "detailsId": 5020
    },
    {
        "id": 1022,
        "mainCategoryId": 1,
        "subCategoryId": 102,
        "name": "SWEATSHIRTS-2",
        "options": "",
        "img": "sweatshirts/1648704518355223.jpg",
        "tagText": "New",
        "detailsId": 5021
    },
    {
        "id": 1023,
        "mainCategoryId": 1,
        "subCategoryId": 102,
        "name": "SWEATSHIRTS-3",
        "options": "",
        "img": "sweatshirts/1648704528718307.jpg",
        "tagText": "New",
        "detailsId": 5022
    },
    {
        "id": 1024,
        "mainCategoryId": 1,
        "subCategoryId": 102,
        "name": "SWEATSHIRTS-4",
        "options": "",
        "img": "sweatshirts/1648704556224184.jpg",
        "tagText": "New",
        "detailsId": 5023
    },
    {
        "id": 1025,
        "mainCategoryId": 1,
        "subCategoryId": 102,
        "name": "SWEATSHIRTS-5",
        "options": "",
        "img": "sweatshirts/1648704567766477.jpg",
        "tagText": "New",
        "detailsId": 5024
    },
    {
        "id": 1026,
        "mainCategoryId": 1,
        "subCategoryId": 102,
        "name": "SWEATSHIRTS-6",
        "options": "",
        "img": "sweatshirts/1648704578180583.jpg",
        "tagText": "New",
        "detailsId": 5025
    },
    {
        "id": 1027,
        "mainCategoryId": 1,
        "subCategoryId": 102,
        "name": "SWEATSHIRTS-7",
        "options": "",
        "img": "sweatshirts/1648704628743742.jpg",
        "tagText": "New",
        "detailsId": 5026
    },
    {
        "id": 1028,
        "mainCategoryId": 1,
        "subCategoryId": 102,
        "name": "SWEATSHIRTS-8",
        "options": "",
        "img": "sweatshirts/1648704669261103.jpg",
        "tagText": "New",
        "detailsId": 5027
    },
    {
        "id": 1021,
        "mainCategoryId": 1,
        "subCategoryId": 102,
        "name": "SWEATSHIRTS-1",
        "options": "",
        "img": "sweatshirts/1648704490843220.jpg",
        "tagText": "New",
        "detailsId": 5020
    },
    {
        "id": 1022,
        "mainCategoryId": 1,
        "subCategoryId": 102,
        "name": "SWEATSHIRTS-2",
        "options": "",
        "img": "sweatshirts/1648704518355223.jpg",
        "tagText": "New",
        "detailsId": 5021
    },
    {
        "id": 1023,
        "mainCategoryId": 1,
        "subCategoryId": 102,
        "name": "SWEATSHIRTS-3",
        "options": "",
        "img": "sweatshirts/1648704528718307.jpg",
        "tagText": "New",
        "detailsId": 5022
    },
    {
        "id": 1024,
        "mainCategoryId": 1,
        "subCategoryId": 102,
        "name": "SWEATSHIRTS-4",
        "options": "",
        "img": "sweatshirts/1648704556224184.jpg",
        "tagText": "New",
        "detailsId": 5023
    },
    {
        "id": 1025,
        "mainCategoryId": 1,
        "subCategoryId": 102,
        "name": "SWEATSHIRTS-5",
        "options": "",
        "img": "sweatshirts/1648704567766477.jpg",
        "tagText": "New",
        "detailsId": 5024
    },
    {
        "id": 1026,
        "mainCategoryId": 1,
        "subCategoryId": 102,
        "name": "SWEATSHIRTS-6",
        "options": "",
        "img": "sweatshirts/1648704578180583.jpg",
        "tagText": "New",
        "detailsId": 5025
    },
    {
        "id": 1027,
        "mainCategoryId": 1,
        "subCategoryId": 102,
        "name": "SWEATSHIRTS-7",
        "options": "",
        "img": "sweatshirts/1648704628743742.jpg",
        "tagText": "New",
        "detailsId": 5026
    },
    {
        "id": 1028,
        "mainCategoryId": 1,
        "subCategoryId": 102,
        "name": "SWEATSHIRTS-8",
        "options": "",
        "img": "sweatshirts/1648704669261103.jpg",
        "tagText": "New",
        "detailsId": 5027
    },
    {
        "id": 1029,
        "mainCategoryId": 1,
        "subCategoryId": 102,
        "name": "SWEATSHIRTS-9",
        "options": "",
        "img": "sweatshirts/1648704683465433.jpg",
        "tagText": "New",
        "detailsId": 5028
    },
    {
        "id": 1030,
        "mainCategoryId": 1,
        "subCategoryId": 103,
        "name": "HAT-1",
        "options": "",
        "img": "hats/1649821134179668.jpg",
        "tagText": "New",
        "detailsId": 5029
    },
    {
        "id": 1031,
        "mainCategoryId": 1,
        "subCategoryId": 103,
        "name": "HAT-2",
        "options": "",
        "img": "hats/1649821157461416.jpg",
        "tagText": "New",
        "detailsId": 5030
    },
    {
        "id": 1032,
        "mainCategoryId": 1,
        "subCategoryId": 103,
        "name": "HAT-3",
        "options": "",
        "img": "hats/1649821171637587.jpg",
        "tagText": "New",
        "detailsId": 5031
    },
    {
        "id": 1033,
        "mainCategoryId": 1,
        "subCategoryId": 103,
        "name": "HAT-4",
        "options": "",
        "img": "hats/1649821185542028.jpg",
        "tagText": "New",
        "detailsId": 5032
    },
    {
        "id": 1034,
        "mainCategoryId": 1,
        "subCategoryId": 103,
        "name": "HAT-5",
        "options": "",
        "img": "hats/1649821202728230.jpg",
        "tagText": "New",
        "detailsId": 5033
    },
    {
        "id": 1035,
        "mainCategoryId": 1,
        "subCategoryId": 103,
        "name": "HAT-6",
        "options": "",
        "img": "hats/1649821219239345.jpg",
        "tagText": "New",
        "detailsId": 5034
    },
    {
        "id": 1036,
        "mainCategoryId": 1,
        "subCategoryId": 103,
        "name": "HAT-7",
        "options": "",
        "img": "hats/1649821234683320.jpg",
        "tagText": "New",
        "detailsId": 5035
    },
    {
        "id": 1037,
        "mainCategoryId": 1,
        "subCategoryId": 103,
        "name": "HAT-8",
        "options": "",
        "img": "hats/1649821247222857.jpg",
        "tagText": "New",
        "detailsId": 5036
    },
    {
        "id": 2010,
        "mainCategoryId": 2,
        "subCategoryId": 201,
        "name": "SWIMWEAR-1",
        "options": "",
        "img": "swimwear/1649820117870136.png",
        "tagText": "New",
        "detailsId": 5037
    },
    {
        "id": 2011,
        "mainCategoryId": 2,
        "subCategoryId": 201,
        "name": "SWIMWEAR-2",
        "options": "",
        "img": "swimwear/1649820136472056.png",
        "tagText": "New",
        "detailsId": 5038
    },
    {
        "id": 2012,
        "mainCategoryId": 2,
        "subCategoryId": 201,
        "name": "SWIMWEAR-3",
        "options": "",
        "img": "swimwear/1649820154206966.png",
        "tagText": "New",
        "detailsId": 5039
    },
    {
        "id": 2013,
        "mainCategoryId": 2,
        "subCategoryId": 201,
        "name": "SWIMWEAR-4",
        "options": "",
        "img": "swimwear/1649820187308323.jpg",
        "tagText": "New",
        "detailsId": 5040
    },
    {
        "id": 2014,
        "mainCategoryId": 2,
        "subCategoryId": 201,
        "name": "SWIMWEAR-5",
        "options": "",
        "img": "swimwear/1649820206795058.jpg",
        "tagText": "New",
        "detailsId": 5041
    },
    {
        "id": 2015,
        "mainCategoryId": 2,
        "subCategoryId": 201,
        "name": "SWIMWEAR-6",
        "options": "",
        "img": "swimwear/1649820224252208.jpg",
        "tagText": "New",
        "detailsId": 5042
    },
    {
        "id": 2016,
        "mainCategoryId": 2,
        "subCategoryId": 201,
        "name": "SWIMWEAR-7",
        "options": "",
        "img": "swimwear/1649820311605348.jpg",
        "tagText": "New",
        "detailsId": 5043
    },
    {
        "id": 2017,
        "mainCategoryId": 2,
        "subCategoryId": 201,
        "name": "SWIMWEAR-8",
        "options": "",
        "img": "swimwear/1649820342987561.png",
        "tagText": "New",
        "detailsId": 5044
    },
    {
        "id": 2018,
        "mainCategoryId": 2,
        "subCategoryId": 201,
        "name": "SWIMWEAR-9",
        "options": "",
        "img": "swimwear/1649820362196019.png",
        "tagText": "New",
        "detailsId": 5045
    },{
        "id": 2020,
        "mainCategoryId": 2,
        "subCategoryId": 202,
        "name": "YOGAWEAR-1",
        "options": "",
        "img": "yogaWear/1649820433394246.png",
        "tagText": "New",
        "detailsId": 5046
    },
    {
        "id": 2021,
        "mainCategoryId": 2,
        "subCategoryId": 202,
        "name": "YOGAWEAR-2",
        "options": "",
        "img": "yogaWear/1649820468999289.jpg",
        "tagText": "New",
        "detailsId": 5047
    },
    {
        "id": 2022,
        "mainCategoryId": 2,
        "subCategoryId": 202,
        "name": "YOGAWEAR-3",
        "options": "",
        "img": "yogaWear/1649820486730560.jpg",
        "tagText": "New",
        "detailsId": 5048
    },
    {
        "id": 2023,
        "mainCategoryId": 2,
        "subCategoryId": 202,
        "name": "YOGAWEAR-4",
        "options": "",
        "img": "yogaWear/1649820518317925.jpg",
        "tagText": "New",
        "detailsId": 5049
    },
    {
        "id": 2024,
        "mainCategoryId": 2,
        "subCategoryId": 202,
        "name": "YOGAWEAR-5",
        "options": "",
        "img": "yogaWear/1649820556151477.jpg",
        "tagText": "New",
        "detailsId": 5050
    },
    {
        "id": 2025,
        "mainCategoryId": 2,
        "subCategoryId": 202,
        "name": "YOGAWEAR-6",
        "options": "",
        "img": "yogaWear/1649820579138864.jpg",
        "tagText": "New",
        "detailsId": 5051
    },
    {
        "id": 2026,
        "mainCategoryId": 2,
        "subCategoryId": 202,
        "name": "YOGAWEAR-7",
        "options": "",
        "img": "yogaWear/1649820622930370.jpg",
        "tagText": "New",
        "detailsId": 5052
    },
    {
        "id": 2027,
        "mainCategoryId": 2,
        "subCategoryId": 202,
        "name": "YOGAWEAR-8",
        "options": "",
        "img": "yogaWear/1649820657428080.jpg",
        "tagText": "New",
        "detailsId": 5053
    },
    {
        "id": 2028,
        "mainCategoryId": 2,
        "subCategoryId": 202,
        "name": "YOGAWEAR-9",
        "options": "",
        "img": "yogaWear/1649820674908527.jpg",
        "tagText": "New",
        "detailsId": 5054
    },
    {
        "id": 2029,
        "mainCategoryId": 2,
        "subCategoryId": 202,
        "name": "YOGAWEAR-10",
        "options": "",
        "img": "yogaWear/1649820691569746.png",
        "tagText": "New",
        "detailsId": 5055
    },{
        "id": 2030,
        "mainCategoryId": 2,
        "subCategoryId": 203,
        "name": "SPORTSWEAR-1",
        "options": "",
        "img": "sportswear/1648706028782845.jpg",
        "tagText": "New",
        "detailsId": 5056
    },
    {
        "id": 2031,
        "mainCategoryId": 2,
        "subCategoryId": 203,
        "name": "SPORTSWEAR-2",
        "options": "",
        "img": "sportswear/1648706071581319.jpg",
        "tagText": "New",
        "detailsId": 5057
    },
    {
        "id": 2032,
        "mainCategoryId": 2,
        "subCategoryId": 203,
        "name": "SPORTSWEAR-3",
        "options": "",
        "img": "sportswear/1648706096662695.jpg",
        "tagText": "New",
        "detailsId": 5058
    },
    {
        "id": 2033,
        "mainCategoryId": 2,
        "subCategoryId": 203,
        "name": "SPORTSWEAR-4",
        "options": "",
        "img": "sportswear/1648706124515686.jpg",
        "tagText": "New",
        "detailsId": 5059
    },
    {
        "id": 2034,
        "mainCategoryId": 2,
        "subCategoryId": 203,
        "name": "SPORTSWEAR-5",
        "options": "",
        "img": "sportswear/1648706138259533.jpg",
        "tagText": "New",
        "detailsId": 5060
    },
    {
        "id": 2035,
        "mainCategoryId": 2,
        "subCategoryId": 203,
        "name": "SPORTSWEAR-6",
        "options": "",
        "img": "sportswear/1648706155274753.jpg",
        "tagText": "New",
        "detailsId": 5061
    },
    {
        "id": 2036,
        "mainCategoryId": 2,
        "subCategoryId": 203,
        "name": "SPORTSWEAR-7",
        "options": "",
        "img": "sportswear/1648706173126468.jpg",
        "tagText": "New",
        "detailsId": 5062
    },
    {
        "id": 2037,
        "mainCategoryId": 2,
        "subCategoryId": 203,
        "name": "SPORTSWEAR-8",
        "options": "",
        "img": "sportswear/1648706192915930.jpg",
        "tagText": "New",
        "detailsId": 5063
    },
    {
        "id": 2038,
        "mainCategoryId": 2,
        "subCategoryId": 203,
        "name": "SPORTSWEAR-9",
        "options": "",
        "img": "sportswear/1648706322333524.jpg",
        "tagText": "New",
        "detailsId": 5064
    }




]