<template>
  <div class="container py-5">
    <!-- Top Title -->
    <div class="text-center mb-5">
      <h1 class="fw-bold">About Us</h1>
      <p class="text-muted">Quality, Efficiency, Professionalism — Helping You Achieve Business Success</p>
    </div>

    <!-- Quality Section -->
    <div class="row align-items-center mb-5">
      <div class="col-lg-6 col-md-12">
        <h2 class="fw-bold">Exceptional Quality</h2>
        <p class="text-muted">We focus on providing high-quality clothing products. Every item undergoes rigorous quality testing to ensure it meets our clients' high standards.</p>
        <p class="text-muted">Selected premium materials and meticulous craftsmanship showcase our endless pursuit of detail.</p>
      </div>
      <div class="col-lg-6 col-md-12">
        <img src="https://via.placeholder.com/500x300" alt="Quality" class="img-fluid rounded fade-in">
      </div>
    </div>

    <!-- Efficiency Section -->
    <div class="row align-items-center mb-5 flex-md-row-reverse">
      <div class="col-lg-6 col-md-12">
        <h2 class="fw-bold">Efficient Service</h2>
        <p class="text-muted">By optimizing supply chain processes, we can quickly respond to customer needs and ensure timely delivery, providing continuous support for your business.</p>
        <p class="text-muted">Regardless of the order size, we complete tasks with the fastest speed and optimal efficiency.</p>
      </div>
      <div class="col-lg-6 col-md-12">
        <img src="https://via.placeholder.com/500x300" alt="Efficiency" class="img-fluid rounded fade-in">
      </div>
    </div>

    <!-- Professionalism Section -->
    <div class="row align-items-center mb-5">
      <div class="col-lg-6 col-md-12">
        <h2 class="fw-bold">Professional Team</h2>
        <p class="text-muted">Our team consists of experienced professionals who are familiar with industry trends and customer needs, providing you with customized solutions.</p>
        <p class="text-muted">Through continuous training and improvement, we always stay at the forefront of the industry, bringing innovation and value to you.</p>
      </div>
      <div class="col-lg-6 col-md-12">
        <img src="https://via.placeholder.com/500x300" alt="Professionalism" class="img-fluid rounded fade-in">
      </div>
    </div>

    <!-- Contact Information -->
    <div class="text-center mb-5">
      <h2 class="fw-bold">Contact Us</h2>
      <p class="text-muted mb-3">For collaboration or more information, please contact us through the following methods:</p>
      <div class="d-flex flex-column align-items-center">
        <p class="text-muted"><strong>Email:</strong><a href="mailto:info@yourcompany.com" class="text-decoration-none">info@yourcompany.com</a></p>
        <p class="text-muted"><strong>Phone:</strong><a href="tel:+123456789" class="text-decoration-none">+123 456 789</a></p>
      </div>
    </div>

    <!-- Vision Section -->
    <div class="text-center">
      <h2 class="fw-bold mb-4">Our Vision</h2>
      <p class="text-muted mb-5">To become a leading global wholesale clothing service provider, partnering with clients to achieve greatness together.</p>
    </div>
  </div>
</template>
