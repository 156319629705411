// src/router/index.js
import {createRouter, createWebHistory} from 'vue-router'
import Home from '../views/Home.vue'   // 导入页面组件
import About from '../views/About.vue'
import Detail from "@/views/Detail.vue";
import Category from "@/views/Category.vue"; // 导入页面组件

// 定义路由
const routes = [
    {
        path: '/', // 根路径
        name: '首页',
        component: Home  // 对应的组件
    },
    {
        path: '/home', // 根路径
        name: '首页1',
        component: Home  // 对应的组件
    },
    {
        path: '/about',
        name: '关于我们',
        component: About // 对应的组件
    },
    {
        path: '/category',
        name: '商品分类',
        component: Category // 对应的组件
    },
    {
        path: '/detail',
        name: '商品详情',
        component: Detail // 对应的组件
    }
]

// 创建 Vue Router 实例
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(to, from, savedPosition) {
        return {top: 0};
    },
})

export default router
