<script>
import {inject} from "vue";
import BreadcrumbsLight from "@/components/breadcrumbs/breadcrumbs-light.vue";
import ProductGallery from "@/components/product/product-gallery.vue";
import ProductInfo from "@/components/product/product-info.vue";
import {useRoute} from "vue-router";
import {useStore} from "vuex";

export default {
  name: "AppDetail",
  components: {
    ProductInfo,
    ProductGallery,
    BreadcrumbsLight,
  },
  setup() {
    console.log(inject('categoryData'))
    const detailsId = useRoute().query.detailsId
    let details = {}
    useStore().state.detail.forEach(item => {
      if (String(item.id) === detailsId) {
        details = item
      }
    })

    // 获取上级组件传递的导航栏数据
    let curCateGory = useRoute().params.curCateGory;
    console.log(curCateGory, "curCate")

    return {
      details,curCateGory
    }
  }
}
</script>

<template>
  <!-- Main Section-->
  <section class="mt-0">
    <!-- Page Content Goes Here -->

    <!-- Breadcrumbs-->
    <div class="bg-dark py-6">
      <div class="container-fluid">
        <BreadcrumbsLight :detailsName="details.title" :catId="details.parentCategoryId"/>
      </div>
    </div>
    <!-- / Breadcrumbs-->

    <div class="container-fluid mt-5">

      <!-- Product Top Section-->
      <div class="row g-9" data-sticky-container>

        <!-- Product Images-->
        <div class="container-fluid pl-7 mt-5">

          <!-- Product Top Section-->
          <div class="row g-9" data-sticky-container>

            <!-- Product Images-->
            <div class="col-12 col-md-5 col-lg-7" style="padding-left: 0!important;padding-right: 0!important; padding-top: 1rem">
              <ProductGallery :imgs="details.imgs"/>
            </div>
            <!-- /Product Images-->

            <!-- Product Information-->
            <div class="col-12 col-md-6 col-lg-5 mx-auto" style="padding-left: 0!important;padding-right: 0!important;margin-top: 5rem !important;">
              <div class="sticky-top top-5">
                <ProductInfo :description="details.description" :title="details.title" />
              </div>
            </div>
            <!-- / Product Information-->
          </div>
          <!-- / Product Top Section-->
<!--          <div class="row g-0">-->

<!--            &lt;!&ndash; Related Products&ndash;&gt;-->
<!--            <div class="col-12" data-aosb="fade-up">-->
<!--&lt;!&ndash;              {{// > product/product-related }}&ndash;&gt;-->
<!--            </div>-->
<!--            &lt;!&ndash; / Related Products&ndash;&gt;-->

<!--            &lt;!&ndash; Reviews&ndash;&gt;-->
<!--            <div class="col-12" data-aosb="fade-up">-->
<!--&lt;!&ndash;              {{> product/product-reviews }}&ndash;&gt;-->
<!--            </div>-->
<!--            &lt;!&ndash; / Reviews&ndash;&gt;-->
<!--          </div>-->
        </div>
        <!-- /Product Images-->

      </div>
      <!-- / Product Top Section-->

    </div>

    <!-- /Page Content -->
  </section>
  <!-- / Main Section-->
</template>

<style scoped>

</style>