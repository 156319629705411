<template>
  <!-- Navbar -->
  <nav class="navbar navbar-expand-lg navbar-light bg-white flex-column border-0 {{ configClassList }} {{ classList }}">
    <div class="container-fluid">
      <div class="w-100">
        <div class="d-flex justify-content-between align-items-center flex-wrap">

          <!-- Logo-->
          <router-link class="navbar-brand fw-bold fs-3 m-0 p-0 flex-shrink-0 order-0" to="/">
            <svg class="f-w-7" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 77.53 72.26">
              <path
                  d="M10.43,54.2h0L0,36.13,10.43,18.06,20.86,0H41.72L10.43,54.2Zm67.1-7.83L73,54.2,68.49,62,45,48.47,31.29,72.26H20.86l-5.22-9L52.15,0H62.58l5.21,9L54.06,32.82,77.53,46.37Z"
                  fill="currentColor" fill-rule="evenodd"/>
            </svg>
          </router-link>
          <!-- / Logo-->

          <!-- Navbar Icons-->
          <ul class="list-unstyled mb-0 d-flex align-items-center order-1 order-lg-2 nav-sidelinks">

            <!-- Mobile Nav Toggler-->
            <li class="d-lg-none">
                        <span class="nav-link text-body d-flex align-items-center cursor-pointer"
                              data-bs-toggle="collapse"
                              data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown"
                              aria-expanded="false"
                              aria-label="Toggle navigation"><i class="ri-menu-line ri-lg me-1"></i></span>
            </li>
            <!-- /Mobile Nav Toggler-->

            <!-- Navbar Search-->
            <li class="d-none d-sm-block">
<!--              <span class="nav-link text-body search-trigger cursor-pointer">...</span>-->

              <!-- Search navbar overlay-->
              <div class="navbar-search d-none">
                <div class="input-group mb-3 h-100">
                                <span class="input-group-text px-4 bg-transparent border-0"><i
                                    class="ri-search-line ri-lg"></i></span>
                  <input type="text" class="form-control text-body bg-transparent border-0"
                         placeholder="Enter your search terms...">
                  <span
                      class="input-group-text px-4 cursor-pointer disable-child-pointer close-search bg-transparent border-0"><i
                      class="ri-close-circle-line ri-lg"></i></span>
                </div>
              </div>
              <div class="search-overlay"></div>
              <!-- / Search navbar overlay-->

            </li>
            <!-- /Navbar Search-->

            <!--            &lt;!&ndash; Navbar Login&ndash;&gt;-->
            <!--            <li class="ms-1 d-none d-lg-inline-block">-->
            <!--              <a class="nav-link text-body" href="{{webRoot}}/login.html">-->
            <!--                Account-->
            <!--              </a>-->
            <!--            </li>-->
            <!--            &lt;!&ndash; /Navbar Login&ndash;&gt;-->

            <!--            &lt;!&ndash; Navbar Cart Icon&ndash;&gt;-->
            <!--            <li class="ms-1 d-inline-block position-relative dropdown-cart">-->
            <!--              <button class="nav-link me-0 disable-child-pointer border-0 p-0 bg-transparent text-body"-->
            <!--                      type="button">-->
            <!--                Bag (2)-->
            <!--              </button>-->
            <!--              {{> cart-dropdown/cart-dropdown }}-->

            <!--            </li>-->
            <!--            &lt;!&ndash; /Navbar Cart Icon&ndash;&gt;-->

          </ul>
          <!-- Navbar Icons-->

          <!-- Main Navigation-->
          <div
              class="flex-shrink-0 collapse navbar-collapse navbar-collapse-light w-auto flex-grow-1 order-2 order-lg-1"
              id="navbarNavDropdown">

            <!-- Menu-->
            <ul class="navbar-nav mx-auto mb-2 mb-lg-0">
              <li class="nav-item">
                <a class="nav-link" href="/" role="button">
                  HOME
                </a>
              </li>
              <li class="nav-item dropdown dropdown position-static">
                <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown"
                   aria-expanded="false">
                  PRODUCT DISPLAY
                </a>
                <div class="dropdown-menu dropdown-megamenu">
                  <div class="container-fluid">
                    <div class="row g-0 g-lg-3">
                      <!-- Menswear Dropdown Menu Links Section-->
                      <div class="col col-lg-12 py-lg-5">
                        <DropdownLinksOne />
                      </div>
                      <!-- /Menswear Dropdown Menu Links Section-->
                    </div>
                  </div>
                </div>
              </li>
<!--              <li class="nav-item dropdown">-->
<!--                <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown"-->
<!--                   aria-expanded="false">-->
<!--                  女装-->
<!--                </a>-->
<!--                <ul class="dropdown-menu">-->
<!--                  <li><a class="dropdown-item" href="{{webRoot}}/category.html">上衣</a></li>-->
<!--                  <li><a class="dropdown-item" href="{{webRoot}}/category.html">内衣</a></li>-->
<!--                  <li><a class="dropdown-item" href="{{webRoot}}/category.html">短裤</a></li>-->
<!--                  <li><a class="dropdown-item" href="{{webRoot}}/category.html">T-Shirts</a></li>-->
<!--                  <li><a class="dropdown-item" href="{{webRoot}}/category.html">鞋子</a></li>-->
<!--                  <li><a class="dropdown-item" href="{{webRoot}}/category.html">配饰</a></li>-->
<!--                </ul>-->
<!--              </li>-->
              <li class="nav-item">
                <router-link class="nav-link" to="about" role="button">
                  ABOUT US
                </router-link>
              </li>
              <!-- / Menu-->
            </ul>
          </div>
          <!-- / Main Navigation-->

        </div>
      </div>
    </div>
  </nav>
  <!-- / Navbar-->
</template>
<script>
import DropdownLinksOne from "@/components/header/navbar/dropdown-links-one.vue";
export default {
  name: "AppNavbar",
  components:{
    DropdownLinksOne
  }
};
</script>