<!-- src/components/Layout.vue -->
<template>
  <div>
    <AppNavbar />
    <main>
      <slot></slot> <!-- 用于展示页面内容 -->
    </main>
    <AppFooter />
  </div>
</template>

<script>
import AppNavbar from '../components/header/navbar/navbar.vue'
import AppFooter from '../components/footer/footer.vue'

export default {
  name: "AppContainer",
  components: {
    AppNavbar,
    AppFooter
  }
};
</script>

<style scoped>
/* 这里可以写一些全局的布局样式 */
</style>
