<template>
  <div class="d-flex justify-content-between items-center pt-5 pb-4 flex-column flex-lg-row">
    <div>
      <Breadcrumbs/>
      <h1 class="fw-bold fs-3 mb-2">PRODUCT DISPLAY ({{ prdCount }})</h1>
      <!--      <p class="m-0 text-muted small">Showing 1 - 9 of 121</p>-->
    </div>
    <!--    <div class="d-flex justify-content-end align-items-center mt-4 mt-lg-0 flex-column flex-md-row">-->

    <!--      &lt;!&ndash; Filter Trigger&ndash;&gt;-->
    <!--      <button class="btn bg-light p-3 me-md-3 d-flex align-items-center fs-7 lh-1 w-100 mb-2 mb-md-0 w-md-auto "-->
    <!--              type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasFilters"-->
    <!--              aria-controls="offcanvasFilters">-->
    <!--        <i class="ri-equalizer-line me-2"></i> Filters-->
    <!--      </button>-->
    <!--      &lt;!&ndash; / Filter Trigger&ndash;&gt;-->

    <!--      &lt;!&ndash; Sort Options&ndash;&gt;-->
    <!--      <select class="form-select form-select-sm border-0 bg-light p-3 pe-5 lh-1 fs-7">-->
    <!--        <option selected>Sort By</option>-->
    <!--        <option value="1">Hi Low</option>-->
    <!--        <option value="2">Low Hi</option>-->
    <!--        <option value="3">Name</option>-->
    <!--      </select>-->
    <!--      &lt;!&ndash; / Sort Options&ndash;&gt;-->
    <!--    </div>-->
  </div>
</template>
<script>
import Breadcrumbs from "@/components/breadcrumbs/breadcrumbs.vue";
import {inject} from "vue";

export default {
  name: "AppToolbar",
  components: {
    Breadcrumbs
  },
  props: {
    // 数字类型prdCount
    prdCount: String
  },
  setup() {
    // return inject('productShow')
  }
}
</script>