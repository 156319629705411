import { createStore } from 'vuex';
import {categoryData} from "../../public/config/category";
import {detail} from "../../public/config/detail";
import {products} from "../../public/config/product";

const store = createStore({
    state: {
        product: products,
        category: categoryData,
        curCateGory: {},
        detail: detail,
    },
    mutations: {
        updateCurrentCategory(state, currentCategory) {
            state.curCateGory = currentCategory;
        }
    },
});

export default store;
